<template>
  <div>
    <TextInput
      v-if="!(single && appliedCoupons.length)"
      id="code"
      v-model="code"
      name="code"
      rules="required|min:9"
      :label="$t('voucherCode')"
      type="text"
      class="text-primary-1-100"
      autocomplete="code"
      rounded
      filled
      border
      :min="9"
    >
    </TextInput>
    <template v-if="appliedCoupons && appliedCoupons.length">
      <div
        v-for="(coupon, index) in appliedCoupons"
        :key="index"
        class="bg-primary-2-07 flex flex-col gap-3 w-full p-4 rounded-2xl"
      >
        <span class="font-bold flex gap-2">
          <svg-icon-loyalty width="20" height="20" />
          {{ $t('voucherApplied') }}</span
        >
        <span class="text-primary-1-100 [ flex items-center flex-wrap justify-start ]">
          {{ $t('promoCodeApplied') }}

          <Money
            v-if="loyaltyVoucher.applied && loyaltyVoucher.applied > 0"
            :value="loyaltyVoucher?.applied"
            class="font-bold px-1"
          />
          <b v-else class="px-1">{{ loyaltyVoucher.applied }}</b>
          <span v-if="loyaltyVoucher?.remaining && loyaltyVoucher.remaining > 0">
            {{ $t('remainingVoucherBalance') }}
            <Money :value="loyaltyVoucher?.remaining" class="font-bold" />
            {{ $t('voucherCannotBeUsedLater') }}
          </span>
        </span>

        <Spinner v-if="loading" class="w-6 h-6 mx-auto" />

        <button
          v-else
          aria-label="clear coupon"
          type="button"
          class="flex gap-2 rounded-xs [ items-center ]"
          @click="$emit('remove', coupon || '')"
        >
          <svg-icon-trash width="20" height="20" />
          <p class="text-sm capitalize text-red-700 underline">{{ $t('removeVoucher') }}</p>
        </button>
      </div>
    </template>

    <div v-if="!appliedCoupons.length" class="mt-5 flex items-center">
      <AppButton
        type="submit"
        :with-arrow="false"
        class="[ flex items-center justify-center ]"
        :disabled="loading"
        inverted
      >
        <Spinner v-if="loading" class="text-white w-6 h-6 mx-auto" />
        <template v-else>
          <span class="uppercase py-2 px-7">
            {{ $t('redeemVoucher') }}
          </span>
        </template>
      </AppButton>

      <slot name="cancel" />
    </div>
  </div>
</template>
<script setup lang="ts">
import type { AppliedDsquareloyaltyMapped } from '~/composables/cart';

defineComponent({
  name: 'Coupons',
});

const { t: $t } = useI18n({
  useScope: 'local',
});

defineProps({
  appliedCoupons: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  single: {
    type: Boolean,
    default: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  loyaltyVoucher: {
    type: Object as PropType<AppliedDsquareloyaltyMapped>,
    default: null,
  },
});

const emit = defineEmits<{
  (e: 'submit', code: string | undefined): void;
  (e: 'remove', code: string | undefined): void;
}>();

const code = ref<string>('');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function applyCoupon() {
  emit('submit', code.value);
}
</script>
<i18n>
      {
        "en": {
          "code": "Code",
          "redeemVoucher": "redeem voucher",
          "promoCodeApplied": " You have successfully applied your loyalty voucher and have received ",
          "voucherCode": "Voucher Code",
          "off": "OFF.",
          "voucherApplied": "Voucher Applied Successfully!",
          "remainingVoucherBalance": "The remaining voucher balance is",
          "voucherCannotBeUsedLater": "and it can't be used later.",
          "removeVoucher": "Remove Voucher"
        },
        "ar": {
          "code": "الكود",
          "redeemVoucher": "تطبيق القسيمه",
          "promoCodeApplied": "تم تطبيق الكود بنجاح",
          "voucherCode": "كود قسيمه",
          "off" : ".خصم",
          "voucherApplied": "تم تطبيق القسيمه بنجاح",
          "remainingVoucherBalance": "المبلغ المتبقي في القسيمة هو",
          "voucherCannotBeUsedLater": "ولا يمكن استخدامه لاحقا.",
          "removeVoucher": "امسح القسيمة"
        }
      }
      </i18n>
