<template>
  <div class="mt-8">
    <TextInput
      v-if="!appliedGiftCard.length"
      id="code"
      v-model="code"
      name="code"
      label="Gift Card"
      type="text"
      autocomplete="code"
      rounded
      filled
      full-border
    >
    </TextInput>

    <template v-if="appliedGiftCard && appliedGiftCard.length">
      <div
        v-for="(giftCard, index) in giftCardData"
        :key="index"
        class="flex flex-col justify-center items-center bg-primary-200 rounded-2xs p-5"
      >
        <div class="flex items-center gap-2 justify-center">
          <svg-icon-gift-card name="gift-card" width="24" height="24" class="flex-shrink-0 self-start" />
          <div class="font-light text-sm [ flex flex-col items-start justify-start gap-x-4 ]">
            <span class="text-primary-700 font-bold"> {{ $t('giftCardAppliedTitle') }}</span>
            <p class="text-primary-700 font-normal">
              <span class="text-primary-700 font-bold">
                {{ $t('valueLabel', { valueLabel: giftCard.value }) }} {{ $t('egp') }}
              </span>
              {{ $t('giftCardAppliedBody') }}
              <span class="text-primary-700 font-bold">
                {{ $t('remainingLabel', { remainingLabel: giftCard.remaining }) }} {{ $t('egp') }}
              </span>
            </p>
            <Spinner v-if="loading" class="w-6 h-6 mx-auto" />

            <button
              v-else
              aria-label="clear gift card"
              type="button"
              class="flex mt-3 rounded-xs self-start [ items-center justify-center ]"
              @click="$emit('remove', giftCard.code)"
            >
              <svg-icon-trash name="trash" width="24" height="24" />
              <span class="ml-2 text-sm font-normal text-red-700 uppercase">{{ $t('removeGiftCard') }}</span>
            </button>
          </div>
        </div>
      </div>
    </template>

    <div v-if="!appliedGiftCard.length" class="mt-5 flex items-center">
      <AppButton
        type="submit"
        :with-arrow="false"
        class="[ flex items-center justify-center ]"
        :disabled="!code || loading"
        inverted
      >
        <Spinner v-if="loading" class="text-white w-6 h-6 mx-auto" />
        <template v-else>
          <span class="uppercase py-2 px-7">
            {{ $t('applyCoupon') }}
          </span>
        </template>
      </AppButton>

      <slot name="cancel" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { AppliedMwGiftCards } from '~/graphql-types.gen';

const { t: $t, n: $n } = useI18n({
  useScope: 'local',
});

const props = defineProps({
  appliedGiftCard: {
    type: Array as PropType<AppliedMwGiftCards[]>,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['remove']);

const code = ref('');

const giftCardData = computed(() =>
  props.appliedGiftCard.map(e => ({
    value: $n(e.applied?.value as number, 'decimal'),
    remaining: $n(e.remaining?.value as number, 'decimal'),
    code: e.code as string,
  })),
);
</script>

<i18n>
  {
    "en": {
      "code": "Code",
      "applyCoupon": "Apply Gift Card",
      "giftCardAppliedTitle": "Gift Card Amount Redeemed!",
      "valueLabel": "{valueLabel}",
      "giftCardAppliedBody": "has been deduced from both your order total, and your gift card balance, remaining gift card balance is",
      "remainingLabel": "{remainingLabel}",
      "removeGiftCard": "remove gift card"
    },
    "ar": {
      "code": "Code",
      "applyCoupon": "استعمال بطاقة الهدايا",
      "giftCardAppliedTitle": "تم استعمال مبلغ بطاقة الهدايا!",
      "valueLabel": "{valueLabel}",
      "giftCardAppliedBody": "تم خصمه من إجمالي طلبك. ويكون رصيد بطاقة الهدايا المتبقي هو",
      "remainingLabel": "{remainingLabel}",
      "removeGiftCard": "ازالة بطاقة الهدايا"
    }
}
</i18n>
